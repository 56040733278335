import * as Yup from 'yup';

const createWithdrawValidation = Yup.object().shape({
  game_id: Yup.string().required(),
  amount: Yup.number().required(),
  payment_name: Yup.string().required(),
  payment_account_name: Yup.string().required(),
  payment_account_id: Yup.string().required(),
  note: Yup.string(),
  payment_qrcode: Yup.string().required(),
  payment_current_amount: Yup.string().required(),
});

export default createWithdrawValidation;
