import { CssBaseline, Box, Container, Typography, Link, ButtonBase, Paper } from '@mui/material';
import MainLayout from 'layouts/main';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

export default function Maintenance() {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
        }}>
        <CssBaseline />
        <Container maxWidth="md">
          <Paper
            sx={{
              m: "auto",
              p: "30px",
              border: "3px solid #FFFF00",
              borderRadius: "14px",
              textAlign: "center"
            }}
          >
            <Typography align="center"
              color="textPrimary"
              variant="h3"
            >
              🛠️ 503 We'll be back soon!
            </Typography>
            <Typography
              align="center"
              color="textPrimary"
              variant="subtitle1"
            >
              The Site is currently down for maintenance
            </Typography>
          </Paper>
        </Container>
      </Box>
    </MainLayout>
  );
}
