import * as Yup from 'yup';

const createDepositValidation = Yup.object().shape({
  game_id: Yup.string().required(),
  amount: Yup.number().required(),
  payment_provider: Yup.string().required(),
  payment_id: Yup.number().required(),
  note: Yup.string(),
  receipt: Yup.string().required(),
});

export default createDepositValidation;
