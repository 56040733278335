import { Box } from '@mui/system';
import React from 'react';
import bgImage from 'images/background.jpg';

export default function MainLayout({ children }) {
  return (
    <Box
      sx={{
        height: '100%',
        background: `url(${bgImage}) center no-repeat`,
        backgroundSize: { xs: '100% 100%', sm: 'cover' },
      }}>
      {children}
    </Box >
  );
}
