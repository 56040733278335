import { createTheme, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import Deposit from "pages/Deposit";
import Maintenance from "pages/Maintenance";
import NotFound from "pages/NotFound";
import Thanks from "pages/Thanks";
import Withdraw from "pages/Withdraw";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false

    },
  }
});

const theme = createTheme({
  palette: {
    mode: 'light',
  },
  typography: {
    fontFamily: '"Poppins","Roboto","Helvetica","Arial","sans-serif"',
  }
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
        <BrowserRouter>
          <Maintenance />
          {/* 
          <Routes>
            <Route path='/' element={<Withdraw />} />
            <Route path='/withdraw' element={<Withdraw />} />
            <Route path='/deposit' element={<Deposit />} />
            <Route path='/thanks' element={<Thanks />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
             */}
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
